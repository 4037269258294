'use client';

import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as Styled from './styled';

type TooltipProviderProps = TooltipPrimitive.TooltipProviderProps;

const TooltipProvider = ({ children, delayDuration = 100, ...props }: TooltipProviderProps) => (
  <TooltipPrimitive.Provider {...props} delayDuration={delayDuration}>
    {children}
  </TooltipPrimitive.Provider>
);

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & { small?: boolean }
>(({ className, sideOffset = 4, children, small, ...props }, ref) => {
  const customBackground = props.style?.background || props.style?.backgroundColor;
  const customArrowStyle = customBackground ? { fill: customBackground as string } : {};

  return (
    <TooltipPrimitive.Portal>
      <Styled.TooltipContent ref={ref} sideOffset={sideOffset} {...props} $small={small}>
        <Styled.TooltipArrow width={10} height={8} style={customArrowStyle} />
        {children}
      </Styled.TooltipContent>
    </TooltipPrimitive.Portal>
  );
});
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip as Root, TooltipTrigger as Trigger, TooltipContent as Content, TooltipProvider as Provider };

// 'use client';
import React from 'react';
import { Primary, Text, Cta, PrimaryOutline, Black, Secondary, Basic, Magic, ButtonProps } from '../Button2';

export type TButtonVariant = 'primary' | 'basic' | 'secondary' | 'text' | 'cta';

interface LegacyButtonProps extends ButtonProps {
  variant?: TButtonVariant;
}

const LegacyButton: React.FC<LegacyButtonProps> = ({ children = 'Default', variant = 'primary', ...props }) => {
  if (variant === 'primary') return <Black {...props}>{children}</Black>;
  if (variant === 'secondary') return <Secondary {...props}>{children}</Secondary>;
  if (variant === 'basic') return <Basic {...props}>{children}</Basic>;
  if (variant === 'text') return <Text {...props}>{children}</Text>;
  if (variant === 'cta') return <Cta {...props}>{children}</Cta>;
  return <Primary {...props}>{children}</Primary>;
};

export default Object.assign(LegacyButton, {
  Black,
  Primary,
  Secondary,
  Text,
  Cta,
  PrimaryOutline,
  Basic,
  Magic,
});
